import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import Funcionarios from './components/funcionarios/lista';
import NovoFuncionario from './components/funcionarios/cria/';
import Perfil from './components/funcionarios/perfil';
import Grupos from './components/grupos/lista/lista';
import Grupo from './components/grupos/grupo/grupo';
import Relatorio from './components/relatorio';
import Feedback from './components/_Common/Feedback';
import PrivateRoute from './components/_Common/PrivateRoute';

class App extends Component {
  render() {
    console.log('new version running');
    return (
      <BrowserRouter>
        <>
          <Feedback />
          <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute
              exact
              path="/funcionarios/"
              component={Funcionarios}
            />
            <PrivateRoute
              path="/funcionarios/novo"
              component={NovoFuncionario}
            />
            <PrivateRoute path="/funcionarios/:id" component={Perfil} />
            <PrivateRoute exact path="/grupos" component={Grupos} />
            <PrivateRoute path="/grupos/:id/relatorio" component={Relatorio} />
            <PrivateRoute path="/grupos/:id" component={Grupo} />
          </Switch>
        </>
      </BrowserRouter>
    );
  }
}

export default App;
