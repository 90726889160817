import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
  withStyles,
  Paper,
  Typography,
  Icon,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { formataDataInput, toDate } from '../../utils';
import Mensal from './mensal';
import Horista from './horista';
import api from '../../_helpers/api.config';
import styles from './styles';

class Relatorio extends Component {
  constructor(props) {
    super(props);
    const { funcionario, pontos, grupo, periodo } = this.props.location.state;
    this.state = {
      funcionario,
      pontos,
      grupo,
      inicio: periodo.inicio,
      fim: periodo.fim,
      modal: false,
      error: null,
    };

    window.scrollTo(0, 0);
  }

  async fetchRelatorio() {
    const { inicio, fim } = this.state;
    try {
      // TODO refatorar para enviar a requisiçao get no formato correto
      const res = await api.post('/api/relatorios/funcionario', {
        funcionario: this.state.funcionario,
        periodo: {
          inicio: toDate(inicio),
          fim: toDate(fim),
        },
      });

      if (res.status === 200) {
        console.log(res.data);

        this.setState({
          pontos: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  feedback(type, msg) {
    this.props.enqueueSnackbar(msg, { variant: type });
  }

  downloadFuncionario(tipo) {
    const { inicio, fim } = this.state;
    try {
      this.feedback('default', 'Preparando download, aguarde...');
      // TODO refatorar para enviar a requisiçao get no formato correto
      const res = api.post(
        '/api/relatorios/funcionario/download',
        {
          funcionario: this.state.funcionario,
          periodo: {
            inicio: toDate(inicio),
            fim: toDate(fim),
          },
          tipo: tipo,
        },
        {
          // responseType: 'arraybuffer',
        }
      );

      res.then((response) => {
        if (response.status !== 200) {
          this.feedback('error', 'Ocorreu um erro ao baixar o aquivo');
        }

        // Decode base64 string
        let binaryString = window.atob(response.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        
        // Convert binary string to bytes
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        } 


        let blob = new Blob([bytes], { type: `application/${tipo}` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.state.funcionario.nome}.${tipo}`;
        link.click();
      });
    } catch (err) {
      console.log(err);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  handleChange = (name) => (event) => {
    const data = formataDataInput(event.target.value);
    this.setState({
      [name]: data,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      funcionario: { nome, cpf, funcao },
    } = this.state;

    return (
      <>
        <Paper className="paper">
          <Grid container>
            <Grid item xs={5}>
              <Typography variant="h5" gutterBottom>
                {nome}
              </Typography>
              <Typography variant="button" gutterBottom>
                {cpf}
              </Typography>
              <Grid item xs={8}>
                <Typography variant="body1" gutterBottom>
                  {funcao}
                </Typography>
              </Grid>
            </Grid>

            {this.opcoesRelatorio(classes)}

            <Grid item container xs={3} alignContent="flex-start" spacing={8}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  size="small"
                  className={classes.button}
                  onClick={() => this.downloadFuncionario('csv')}>
                  Baixar Planilha
                  <Icon className={classes.rightIcon} size="small">
                    arrow_downward
                  </Icon>
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="small"
                  className={classes.button}
                  onClick={() => this.downloadFuncionario('pdf')}>
                  Baixar Cartão Ponto
                  <Icon className={classes.rightIcon} size="small">
                    assessment
                  </Icon>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {this.horistaOuMensal()}
        </Paper>
        {/* { this.renderModalExclusao() } */}
      </>
    );
  }

  opcoesRelatorio(classes) {
    return (
      <Grid container item xs={4} spacing={0} className={classes.periodo}>
        <Grid item xs={5}>
          <TextField
            id="valorHora"
            name="valorHora"
            fullWidth
            label="De"
            value={this.state.inicio}
            onChange={this.handleChange('inicio')}
            variant="outlined"
            inputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            // margin="dense"
            id="valorHora"
            name="valorHora"
            fullWidth
            label="Até"
            value={this.state.fim}
            onChange={this.handleChange('fim')}
            variant="outlined"
            inputProps={{
              className: classes.input,
            }}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => this.fetchRelatorio()}>
            atualizar relatório
            <Icon className={classes.rightIcon} size="small">
              autorenew
            </Icon>
          </Button>
        </Grid>
      </Grid>
    );
  }

  horistaOuMensal() {
    if (this.state.grupo.regime === 'Mensal') {
      return (
        <Mensal
          classes={this.props.classes}
          funcionario={this.state.funcionario}
          pontos={this.state.pontos}
          atualizar={() => this.fetchRelatorio()}
        />
      );
    }

    if (this.state.grupo.regime === 'Horista') {
      return (
        <Horista
          classes={this.props.classes}
          funcionario={this.state.funcionario}
          pontos={this.state.pontos}
          atualizar={() => this.fetchRelatorio()}
        />
      );
    }
  }
}

export default withSnackbar(withStyles(styles)(Relatorio));
