export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },

  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },

  pullLeft: {
    position: 'absolute',
    left: theme.spacing.unit * 2,
  },

  paper: {
    marginTop: '3em',
    margin: 'auto',
    width: '30%',
    padding: theme.spacing.unit * 2,
  },
});
