import React, { Component } from 'react';
import {
  withStyles,
  Paper,
  Typography,
  Grid,
  Button,
  Icon,
} from '@material-ui/core';
import ExcluiFuncionario from '../exclui';
import EditaFuncionario from '../edita';
import api from '../../../_helpers/api.config';
import styles from './styles';

class Perfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      funcionario: '',
      isLoading: true,
      success: false,
      error: null,
      excluir: false,
      editar: false,
    };

    this.editaSuccess = this.editaSuccess.bind(this);
  }

  async componentDidMount() {
    try {
      const {
        match: { params },
      } = this.props;
      const res = await api.get(`/api/funcionario/${params.id}`);
      this.setState({
        funcionario: res.data,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        error: 'Não foi possível acessar o perfil do funcionário.',
      });
    }
  }

  confirmaExclusao = () => {
    this.setState({ excluir: !this.state.excluir });
  };

  renderModalExclusao() {
    if (this.state.excluir) {
      return (
        <ExcluiFuncionario
          funcionario={this.state.funcionario}
          hocHandler={this.confirmaExclusao}
        />
      );
    }
  }

  edita = () => {
    this.setState({ editar: !this.state.editar });
  };

  editaSuccess = (funcionario) => {
    this.setState({ funcionario, editar: false });
  };

  renderEdicao() {
    if (this.state.editar) {
      return (
        <EditaFuncionario
          funcionario={this.state.funcionario}
          editaSuccess={this.editaSuccess}
        />
      );
    }
  }

  exibeJornada(classes, jornada, grupo) {
    if (grupo.regime === 'Mensal') {
      return (
        <>
          <Grid item xs={1} md={2}>
            <div className={classes.label}>Jornada</div>
            <div className={classes.label}>Entrada</div>
            <div className={classes.label}>Saída</div>
          </Grid>

          <Grid item xs={1} md={2}>
            <div className={classes.label}>Manhã</div>
            <div>{jornada.manha.entrada}</div>
            <div>{jornada.manha.saida}</div>
          </Grid>

          <Grid item xs={1} md={2}>
            <div className={classes.label}>Tarde</div>
            <div>{jornada.tarde.entrada}</div>
            <div>{jornada.tarde.saida}</div>
          </Grid>

          <Grid item xs={1} md={2}>
            <div className={classes.label}>Sábado</div>
            <div>{jornada.sabado.entrada}</div>
            <div>{jornada.sabado.saida}</div>
          </Grid>
        </>
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return <p>Carregando...</p>;
    }

    const {
      funcionario: { nome, cpf, senha, funcao, valorHora, grupo, jornada },
    } = this.state;

    const { classes } = this.props;

    return (
      <>
        <Paper className="paper">
          <Grid container>
            <Grid item xs={5} className={classes.topInfo}>
              <Typography variant="h5" className={classes.noMb}>
                {nome}
              </Typography>
              <Typography variant="subtitle1" className={classes.noMb}>
                {grupo.nome} - Regime {grupo.regime}
              </Typography>
              <Typography variant="button" gutterBottom>
                {funcao}
              </Typography>
            </Grid>

            <Grid item md={5} container spacing={0}>
              {this.exibeJornada(classes, jornada, grupo)}
            </Grid>

            <Grid item xs={2}>
              <Button
                // variant="contained"
                // color="default"
                fullWidth
                size="small"
                onClick={() => this.confirmaExclusao()}
                className={classes.button}>
                Excluir
                <Icon className={classes.rightIcon} size="small">
                  delete
                </Icon>
              </Button>

              <Button
                variant="contained"
                color="default"
                fullWidth
                size="small"
                onClick={() => this.edita()}
                className={classes.button}>
                Editar
                <Icon className={classes.rightIcon} size="small">
                  edit
                </Icon>
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={2} md={1}>
              <div className={classes.label}>Senha: </div>
              <div className={classes.label}>Valor Hora: </div>
              <div className={classes.label}>CPF: </div>
            </Grid>

            <Grid item xs={6} md={6}>
              <div className={classes.info}>{senha}</div>
              <div className={classes.info}>
                R$ {parseFloat(valorHora).toFixed(2)}
              </div>
              <div className={classes.info}>{cpf}</div>
            </Grid>
          </Grid>
          {this.renderModalExclusao()}
        </Paper>

        {this.renderEdicao()}
      </>
    );
  }
}

export default withStyles(styles)(Perfil);
