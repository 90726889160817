export default theme => ({
  topInfo: {
    marginBottom: 10,
  },

  noMb: {
    marginBottom: -5,
  },

  label: {
    fontWeight: 300,
  },

  info: {
    marginLeft: 10,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  button: {
    marginBottom: 10,
  },
});
