import React, { Component } from 'react';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import Cria from '../cria/cria';
import { withStyles, Paper, Typography } from '@material-ui/core';
import api from '../../../_helpers/api.config';
import styles from './styles';
import validationSchema from '../validation';
import { errorHandler } from '../../../utils';

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grupos: [],
      isLoading: true,
      success: false,
      fail: false,
      error: '',
    };
  }

  async componentDidMount() {
    try {
      const grupos = await api.get('/api/configs/grupo');
      if (grupos.data.length === 0)
        this.props.enqueueSnackbar('Não há nenhum grupo cadastrado!', {
          variant: 'error',
        });
      this.setState({
        grupos: grupos.data,
        isLoading: false,
        // success: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  feedback(type, msg) {
    this.props.enqueueSnackbar(msg, { variant: type });
  }

  render() {
    const {
      funcionario: { _id, nome, cpf, senha, funcao, valorHora, grupo, jornada },
    } = this.props;

    const values = {
      nome,
      cpf,
      senha,
      funcao,
      valorHora,
      grupo,
      regime: grupo.regime,
      grupos: this.state.grupos,
      manhaEntrada: jornada.manha.entrada,
      manhaSaida: jornada.manha.saida,
      tardeEntrada: jornada.tarde.entrada,
      tardeSaida: jornada.tarde.saida,
      sabadoEntrada: jornada.sabado.entrada,
      sabadoSaida: jornada.sabado.saida,
      classes: this.props.classes,
    };

    if (this.state.isLoading) {
      return <p>Carregando...</p>;
    }

    const { classes, editaSuccess } = this.props;

    return (
      <Paper className="paper">
        <Typography variant="h5" gutterBottom className={classes.top}>
          Editar funcionário
        </Typography>
        <Formik
          render={(props) => <Cria {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            // console.log(values);

            const funcionario = {
              _id: _id,
              nome: values.nome,
              senha: values.senha,
              grupo: values.grupo._id,
              funcao: values.funcao,
              cpf: values.cpf,
              valorHora: values.valorHora,
              jornada: {
                manha: {
                  entrada: values.manhaEntrada,
                  saida: values.manhaSaida,
                },
                tarde: {
                  entrada: values.tardeEntrada,
                  saida: values.tardeSaida,
                },
                sabado: {
                  entrada: values.sabadoEntrada,
                  saida: values.sabadoSaida,
                },
              },
            };

            try {
              const res = await api.put(`/api/funcionario/${_id}`, {
                funcionario,
              });

              if (res.status === 200 && res.data._id === _id) {
                this.feedback(
                  'success',
                  `Funcionário ${values.nome} editado com sucesso`
                );
                // altera o state do parent
                editaSuccess(res.data);
              }
            } catch (error) {
              this.feedback('error', errorHandler(error.response.data));
            }

            // actions.setSubmitting(false);
          }}
        />
      </Paper>
    );
  }
}

export default withSnackbar(withStyles(styles)(InputForm));
