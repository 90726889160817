export default theme => ({
  top: {
    marginBottom: 30,
  },

  selectEmpty: {
    marginTop: 0,
  },

  horarios: {
    marginTop: '2em',
    marginBottom: 20,
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});
