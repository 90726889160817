import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CssBaseline, Toolbar } from '@material-ui/core';

import AppBar from '../appBar/appbar';
// import MenuLateral from '../menuLateral/menuLateral';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('user') ? (
        <>
          <CssBaseline />
          <AppBar />
          {/* <MenuLateral /> */}
          <div className="content">
            <Toolbar />
            <Component {...props} />
          </div>
        </>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default PrivateRoute;
