import React from 'react';
import { formatCpf } from '@brazilian-utils/formatters';
import {
  TextField,
  Grid,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Fab,
  Icon,
} from '@material-ui/core';
import { formataHora, formataValor } from '../../../utils';

const Form = props => {
  const {
    values: {
      nome,
      cpf,
      senha,
      funcao,
      valorHora,
      grupo,
      grupos,
      regime,
      manhaEntrada,
      manhaSaida,
      tardeEntrada,
      tardeSaida,
      sabadoEntrada,
      sabadoSaida,
      classes,
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  const change = (name, e) => {
    e.persist();
    setFieldTouched(name, true, false);
    if (name === 'grupo') {
      if (e.target.value.regime === 'Mensal') {
        setFieldValue('regime', true);
      } else {
        setFieldValue('regime', false);
      }
    }

    switch (name) {
      case 'cpf':
        let cpf = formatCpf(e.target.value);
        setFieldValue(name, cpf);
        break;

      case 'manhaEntrada':
        setFieldValue(name, formataHora(e.target.value));
        break;

      case 'manhaSaida':
        setFieldValue(name, formataHora(e.target.value));
        break;

      case 'tardeEntrada':
        setFieldValue(name, formataHora(e.target.value));
        break;

      case 'tardeSaida':
        setFieldValue(name, formataHora(e.target.value));
        break;

      case 'sabadoEntrada':
        setFieldValue(name, formataHora(e.target.value));
        break;

      case 'sabadoSaida':
        setFieldValue(name, formataHora(e.target.value));
        break;

      case 'senha':
        setFieldValue(name, e.target.value.slice(0, 4));
        break;

      case 'valorHora':
        setFieldValue(name, formataValor(e.target.value));
        break;

      default:
        handleChange(e);
        // console.log(errors);
        break;
    }
  };
  
  const Regime = regime => {
    if (regime) {
      return (
        <Grid container item xs={12} spacing={16}>
          <Grid container item xs={6} spacing={16}>
            {/* <Typography variant="button">Jornada</Typography> */}
            <Grid container item xs={12} spacing={16}>
              <Grid item xs={4}>
                <TextField
                  id="manhaEntrada"
                  name="manhaEntrada"
                  helperText={touched.manhaEntrada ? errors.manhaEntrada : ''}
                  error={touched.manhaEntrada && Boolean(errors.manhaEntrada)}
                  label="Entrada: Manhã"
                  fullWidth
                  value={manhaEntrada}
                  onChange={change.bind(null, 'manhaEntrada')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="manhaSaida"
                  name="manhaSaida"
                  helperText={touched.manhaSaida ? errors.manhaSaida : ''}
                  error={touched.manhaSaida && Boolean(errors.manhaSaida)}
                  label="Saída: Manhã"
                  fullWidth
                  value={manhaSaida}
                  onChange={change.bind(null, 'manhaSaida')}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={16}>
              <Grid item xs={4}>
                <TextField
                  id="tardeEntrada"
                  name="tardeEntrada"
                  helperText={touched.tardeEntrada ? errors.tardeEntrada : ''}
                  error={touched.tardeEntrada && Boolean(errors.tardeEntrada)}
                  label="Entrada: Tarde"
                  fullWidth
                  value={tardeEntrada}
                  onChange={change.bind(null, 'tardeEntrada')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="tardeSaida"
                  name="tardeSaida"
                  helperText={touched.tardeSaida ? errors.tardeSaida : ''}
                  error={touched.tardeSaida && Boolean(errors.tardeSaida)}
                  label="Saída: Tarde"
                  fullWidth
                  value={tardeSaida}
                  onChange={change.bind(null, 'tardeSaida')}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={16}>
              <Grid item xs={4}>
                <TextField
                  id="sabadoEntrada"
                  name="sabadoEntrada"
                  helperText={touched.sabadoEntrada ? errors.sabadoEntrada : ''}
                  error={touched.sabadoEntrada && Boolean(errors.sabadoEntrada)}
                  label="Entrada: Sábado"
                  fullWidth
                  value={sabadoEntrada}
                  onChange={change.bind(null, 'sabadoEntrada')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="sabadoSaida"
                  name="sabadoSaida"
                  helperText={touched.sabadoSaida ? errors.sabadoSaida : ''}
                  error={touched.sabadoSaida && Boolean(errors.sabadoSaida)}
                  label="Saída: Sábado"
                  fullWidth
                  value={sabadoSaida}
                  onChange={change.bind(null, 'sabadoSaida')}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={5} spacing={16}>
            {/* <Typography variant="button">Regime</Typography> */}
            <Grid item xs={12}>
              No regime mensal, o sistema calcula a jornada de cada funcionário,
              registra horas normais, horas banco, horas totais e realiza o
              ponto automático.
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <TextField
            id="nome"
            name="nome"
            helperText={touched.nome ? errors.nome : ''}
            error={touched.nome && Boolean(errors.nome)}
            label="Nome Completo"
            value={nome}
            onChange={change.bind(null, 'nome')}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="cpf"
            name="cpf"
            helperText={touched.cpf ? errors.cpf : ''}
            error={touched.cpf && Boolean(errors.cpf)}
            label="CPF"
            fullWidth
            value={cpf}
            onChange={change.bind(null, 'cpf')}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="senha"
            name="senha"
            helperText={touched.senha ? errors.senha : ''}
            error={touched.senha && Boolean(errors.senha)}
            label="Senha"
            fullWidth
            value={senha}
            onChange={change.bind(null, 'senha')}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="funcao"
            name="funcao"
            helperText={touched.funcao ? errors.funcao : ''}
            error={touched.funcao && Boolean(errors.funcao)}
            label="Função"
            fullWidth
            value={funcao}
            onChange={change.bind(null, 'funcao')}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="valorHora"
            name="valorHora"
            helperText={touched.valorHora ? errors.valorHora : ''}
            error={touched.valorHora && Boolean(errors.valorHora)}
            label="Valor Hora"
            fullWidth
            value={valorHora}
            onChange={change.bind(null, 'valorHora')}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="grupo">Grupo</InputLabel>
            <Select
              value={grupo}
              onChange={change.bind(null, 'grupo')}
              input={<OutlinedInput labelWidth={50} name="grupo" id="grupo" />}>
              {grupos.map(grupo => (
                <MenuItem value={grupo} key={grupo._id}>
                  {grupo.nome} - ({grupo.regime})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {Regime(regime)}
      </Grid>
      {/* 
      <Button
        type="submit"
        fullWidth
        variant="raised"
        color="primary"
        disabled={!isValid}>
        Submit
      </Button> */}

      <Fab
        variant="extended"
        type="submit"
        color="secondary"
        disabled={!isValid}
        className={classes.fab}>
        Salvar
        <Icon className={classes.rightIcon}>check_circle</Icon>
      </Fab>
    </form>
  );
};

export default Form;
