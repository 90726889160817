import axios from 'axios';

export function isLastChar(index, str) {
  return index === str.length - 1;
}

export function onlyNumbers(str) {
  return str.replace(/[^\d]/g, '');
}

export function formataDataInput(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 8)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([1, 3].includes(index)) return `${result}/`;
      }
      return result;
    }, '');
}

export function toDate(dateStr) {
  const [day, month, year] = dateStr.split('/');
  return new Date(year, month - 1, day);
}

export function formataHora(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 6)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([1, 3].includes(index)) return `${result}:`;
      }
      return result;
    }, '');
}

export function formataValor(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 4)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([1].includes(index)) return `${result}.`;
      }
      return result;
    }, '');
}

export function formataCelular(value) {
  const numericData = onlyNumbers(value);
  if (value.includes('+')) {
    // número internacionais
    return value.replace(/(^\+)+[^\d^(^)^ ^\-^]/g, '').slice(0, 22);
  }
  // números brasileiros
  return numericData
    .slice(0, 11)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;

      if (!isLastChar(index, numericData)) {
        if ([0].includes(index)) return `(${result}`;
        if ([1].includes(index)) return `${result}) `;
        if ([5].includes(index)) return `${result}-`;
      }
      return result;
    }, '');
}

export function errorHandler(errors) {
  if (typeof errors === 'object') {
    let result = '';
    for (let error of errors) {
      result += `${error.message}.`;
    }
    return result;
  }

  return errors;
}

export function formataCep(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 8)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([4].includes(index)) return `${result}-`;
      }
      return result;
    }, '');
}

export async function consultaCep(cep) {
  try {
    const res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}
