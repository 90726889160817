export default (theme) => ({
  table: {
    minWidth: 700,
    marginTop: '2rem',
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  button: {
    marginBottom: '0.5em',
  },

  input: {
    padding: '9px 14px',
  },

  regime: {
    marginTop: '50px',
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },

  fabIcon: {
    marginRight: theme.spacing.unit,
  },
});
