import api from '../../../_helpers/api.config';
// import { authHeader } from '../_helpers';

export const login = async (email, password) => {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await api.post('/api/login', { email, password }, config);
    localStorage.setItem('user', res.data.token);
    return { success: true };
  } catch (error) {
    return erroHandler(error);
  }
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
};

function erroHandler(error) {
  const { response } = error;
  console.log(error.response);

  if (!response) {
    return { error: 'Sem conexão' };
  }

  if (response.status === 400) {
    return { error: response.data.error };
  }

  // auto logout if 401 response returned from api
  if (response.status === 401) {
    logout();
    window.location.reload(true);
  }

  return false;
}
