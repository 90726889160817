import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Fab,
  Icon,
  Tooltip,
} from '@material-ui/core';
import NovoGrupo from '../novo/novo';
import api from '../../../_helpers/api.config';

const styles = (theme) => ({
  table: {
    minWidth: 700,
    marginTop: '2rem',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

class ListaGrupos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      rows: [],
      error: null,
      notify: this.props.location.state,
    };

    this.updateTable = this.updateTable.bind(this);
  }

  async componentDidMount() {
    try {
      const res = await api.get('/api/configs/grupo');
      const rows = res.data;
      this.setState({ rows });
    } catch (err) {
      console.log(err);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  updateTable(data) {
    const rows = this.state.rows;
    rows.push(data);
    this.setState({ rows });
  }

  renderModal() {
    if (this.state.modal) {
      return (
        <NovoGrupo
          hocHandler={this.handleModal}
          updateTable={this.updateTable}
          router={this.props.router}
        />
      );
    }
  }

  render() {
    const { classes, match } = this.props;

    return (
      <>
        <Paper className="paper">
          <Typography variant="h5" gutterBottom>
            Grupos
          </Typography>
          <Table className={classes.table} padding={'dense'}>
            <TableHead>
              <TableRow className="tableHeader">
                <TableCell>Grupo</TableCell>
                <TableCell align="center">Regime</TableCell>
                <TableCell align="center">Funcionários</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map((grupo) => (
                <TableRow
                  key={grupo._id}
                  hover={true}
                  className="pointer"
                  component={Link}
                  to={{
                    pathname: `${match.url}/${grupo._id}`,
                    state: grupo,
                  }}>
                  <TableCell component="th" scope="row">
                    {grupo.nome}
                  </TableCell>
                  <TableCell align="center">{grupo.regime}</TableCell>
                  <TableCell align="center">
                    {grupo.funcionarios.length}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Tooltip
            title="Adicionar Grupo"
            aria-label="Adicionar Grupo"
            size="large">
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={this.handleModal}>
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </Paper>
        {this.renderModal()}
      </>
    );
  }
}

ListaGrupos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListaGrupos);
