import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fab,
} from '@material-ui/core';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import TimeCell from '../TimeCell';
import api from '../../_helpers/api.config';

let edicoes = [];

export default (props) => {
  const [error, setError] = useState(0);
  const [touched, setTouched] = useState(false);
  const { classes, funcionario, pontos } = props;

  const handleError = (err) => {
    err ? setError(error + 1) : setError(error - 1);
  };

  const setArray = (data, time, key, old) => {
    setTouched(true);

    // evita duplicatas e adiciona opçao pra apagar ediçao
    if (!time && key) {
      edicoes = edicoes.filter((elem) => {
        return elem.key !== key;
      });
      return;
    }

    const ponto = new Date(
      parseInt(data.split('/')[2]),
      parseInt(data.split('/')[1]) - 1,
      parseInt(data.split('/')[0]),
      parseInt(time.split(':')[0]),
      parseInt(time.split(':')[1]),
      parseInt(time.split(':')[2])
    );

    // remove duplicatas
    edicoes = edicoes.filter((elem) => {
      return elem.key !== key;
    });

    if (old && old !== '-' && old !== '' && old !== ' ') {
      const original = new Date(
        parseInt(data.split('/')[2]),
        parseInt(data.split('/')[1]) - 1,
        parseInt(data.split('/')[0]),
        parseInt(old.split(':')[0]),
        parseInt(old.split(':')[1]),
        parseInt(old.split(':')[2])
      );
      edicoes.push({ ponto, key, original });
    } else {
      edicoes.push({ ponto, key, original: null });
    }
    console.log(edicoes);
  };

  const submitChanges = async () => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    try {
      const res = await api.post(
        '/api/ponto/edita',
        { pontos: edicoes, funcionario: funcionario._id },
        config
      );
      edicoes = [];
      setTouched(false);
      props.atualizar();
      return { success: true };
    } catch (error) {
      console.log(error);
      console.log(error.response);

      // return erroHandler(error);
    }
  };

  const deletaPonto = async (data, time) => {
    const ponto = new Date(
      parseInt(data.split('/')[2]),
      parseInt(data.split('/')[1]) - 1,
      parseInt(data.split('/')[0]),
      parseInt(time.split(':')[0]),
      parseInt(time.split(':')[1]),
      parseInt(time.split(':')[2])
    );

    console.log(ponto);

    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    try {
      await api.delete(
        '/api/ponto',
        { data: { ponto, funcionario: funcionario._id } },
        config
      );
      props.atualizar();
      return { success: true };
    } catch (error) {
      console.log(error);
      console.log(error.response);

      // return erroHandler(error);
    }
  };

  return (
    <>
      <Table className={classes.table} padding={'checkbox'}>
        <TableHead className="tableHeader">
          <TableRow>
            <TableCell align="center">Dia</TableCell>
            <TableCell align="center">Data</TableCell>
            <TableCell align="center">Entrada</TableCell>
            <TableCell align="center">Saída</TableCell>
            <TableCell align="center">Entrada</TableCell>
            <TableCell align="center">Saída</TableCell>
            <TableCell align="center">Entrada</TableCell>
            <TableCell align="center">Saída</TableCell>
            <TableCell align="center">Horas Totais</TableCell>
            <TableCell align="center">Horas Normais</TableCell>
            <TableCell align="center">Horas Banco</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {infos(funcionario, pontos, handleError, setArray, deletaPonto)}
        </TableBody>
      </Table>
      {touched ? showSave(error, classes, submitChanges) : null}
    </>
  );
};

const infos = (funcionario, resultados, handleError, setArray, deletaPonto) => {
  return resultados.map((ponto, index) => {
    const { dia, data, pontos, extra, normal, total } = ponto;

    if (index === resultados.length - 1) {
      return totais(ponto);
    }

    if (!pontos) {
      return (
        <TableRow key={data} hover={true} className="pointer">
          <TableCell component="th" scope="row">
            {dia}
          </TableCell>
          <TableCell align="center">{data}</TableCell>
          <TimeCell
            deletaPonto={deletaPonto}
            aKey={0}
            handler={handleError}
            data={data}
            setArray={setArray}
          />
          <TimeCell
            deletaPonto={deletaPonto}
            aKey={1}
            handler={handleError}
            data={data}
            setArray={setArray}
          />
          <TimeCell
            deletaPonto={deletaPonto}
            aKey={2}
            handler={handleError}
            data={data}
            setArray={setArray}
          />
          <TimeCell
            deletaPonto={deletaPonto}
            aKey={3}
            handler={handleError}
            data={data}
            setArray={setArray}
          />
          <TimeCell
            deletaPonto={deletaPonto}
            aKey={4}
            handler={handleError}
            data={data}
            setArray={setArray}
          />
          <TimeCell
            deletaPonto={deletaPonto}
            aKey={5}
            handler={handleError}
            data={data}
            setArray={setArray}
          />
          <TableCell align="center">-</TableCell>
          <TableCell align="center">-</TableCell>
          <TableCell align="center">-</TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={data} hover={true} className="pointer">
        <TableCell align="center" component="th" scope="row">
          {dia}
        </TableCell>
        <TableCell align="center">{data}</TableCell>
        <TimeCell
          deletaPonto={deletaPonto}
          aKey={0}
          info={formataHora(pontos[0])}
          handler={handleError}
          data={data}
          setArray={setArray}
        />
        <TimeCell
          deletaPonto={deletaPonto}
          aKey={1}
          info={formataHora(pontos[1])}
          handler={handleError}
          data={data}
          setArray={setArray}
        />
        <TimeCell
          deletaPonto={deletaPonto}
          aKey={2}
          info={formataHora(pontos[2])}
          handler={handleError}
          data={data}
          setArray={setArray}
        />
        <TimeCell
          deletaPonto={deletaPonto}
          aKey={3}
          info={formataHora(pontos[3])}
          handler={handleError}
          data={data}
          setArray={setArray}
        />
        <TimeCell
          deletaPonto={deletaPonto}
          aKey={4}
          info={formataHora(pontos[4])}
          handler={handleError}
          data={data}
          setArray={setArray}
        />
        <TimeCell
          deletaPonto={deletaPonto}
          aKey={5}
          info={formataHora(pontos[5])}
          handler={handleError}
          data={data}
          setArray={setArray}
        />
        <TableCell align="center">{total}</TableCell>
        <TableCell align="center">{normal}</TableCell>
        <TableCell align="center">{extra}</TableCell>
      </TableRow>
    );
  });
};

const formataHora = (data) => {
  if (data) {
    return format(data, 'HH:mm:ss');
  }
  return '-';
};

const totais = (results) => {
  const { extra, normal, total, faltante } = results;

  return (
    <TableRow key="last" hover={true} className="pointer">
      <TableCell component="th" scope="row">
        -
      </TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">TOTAL</TableCell>
      <TableCell align="center">
        {' '}
        {((total - faltante) / 3600).toFixed(2)}
      </TableCell>
      <TableCell align="center">{(normal / 3600).toFixed(2)}</TableCell>
      <TableCell align="center">{(extra / 3600).toFixed(2)}</TableCell>
    </TableRow>
  );
};

const showSave = (error, classes, submitChanges) => {
  return (
    <Fab
      color="secondary"
      aria-label="Add"
      variant="extended"
      onClick={() => submitChanges()}
      disabled={error > 0}
      className={classes.fab}>
      <FontAwesomeIcon icon={faCheck} className={classes.fabIcon} fixedWidth />
      Salvar alterações
    </Fab>
  );
};
