import React, { useState, useEffect } from 'react';
import {
  withStyles,
  TableCell,
  TextField,
  Popper,
  Fade,
  Paper,
  Button,
  Typography,
  ClickAwayListener,
} from '@material-ui/core';
import styles from './styles';
import { formataHora } from '../../_helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

function TimeCell(props) {
  const { info, classes, handler, data, setArray, aKey, deletaPonto } = props;

  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [value, setValue] = useState(info || '');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const key = '' + parseInt(data.split(':')[0]) + aKey;
  const id = open ? 'option-popper' : null;
  const idDel = del ? 'del-popper' : null;

  useEffect(() => {
    setValue(info);
    return () => {
      setEdit(false);
    };
  }, [info]);

  const handleChange = () => (event) => {
    setValue(formataHora(event.target.value));
  };

  const handlePopperBlur = () => (event) => {
    // setEdit(false);
    setOpen(false);
    setDel(false);
  };

  const handleBlur = () => (event) => {
    const val = event.target.value;
    if (!val || val === info) {
      setArray(data, null, key, info);
      setEdit(false);
      return;
    }

    if (error && isValid(value)) {
      setError(false);
      handler(false);
      setArray(data, value, key, info);
      return;
    }

    if (error && !isValid(value)) {
      setError(true);
      return;
    }

    if (isValid(value)) {
      setError(false);
      setArray(data, value, key, info);
    } else {
      handler(true);
      setError(true);
    }
  };

  const editField = () => {
    return (
      <TextField
        value={value}
        autoFocus
        className={classes.margin}
        onChange={handleChange()}
        onBlur={handleBlur()}
        error={error}
        helperText={error ? 'Inválido' : ''}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
        }}
        label={info || '-'}
        variant="outlined"
      />
    );
  };

  const editOrDel = () => (event) => {
    const { currentTarget } = event;
    setAnchor(currentTarget);
    setDel(false);
    setEdit(true);
    if (del) setOpen(false);
    else setOpen(!open);
  };

  const handleEdit = () => {
    setEdit(true);
    setDel(false);
    setOpen(false);
  };

  const handleDel = () => {
    setDel(true);
    setEdit(false);
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handlePopperBlur()}>
      <>
        <TableCell align="center" onClick={editOrDel()}>
          {edit ? editField() : info || '-'}
        </TableCell>
        <Popper
          id={id}
          open={open}
          anchorEl={anchor}
          transition
          placement="top">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paperPop}>
                <Button onClick={() => handleDel()} size="small">
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                  {/* Excluir */}
                </Button>
              </Paper>
            </Fade>
          )}
        </Popper>

        <Popper
          id={idDel}
          open={del}
          anchorEl={anchor}
          transition
          placement="top">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paperPop}>
                <Typography variant="h6" align="center">
                  Confirma exclusão?
                </Typography>
                <Button
                  onClick={editOrDel()}
                  size="small"
                  fullWidth
                  className={classes.button}
                  variant="contained">
                  <FontAwesomeIcon
                    icon={faTimes}
                    fixedWidth
                    className={classes.pullLeft}
                  />
                  Não
                </Button>
                <Button
                  onClick={() => deletaPonto(data, value)}
                  size="small"
                  fullWidth
                  className={classes.button}
                  color="secondary"
                  variant="contained">
                  <FontAwesomeIcon
                    icon={faCheck}
                    fixedWidth
                    className={classes.pullLeft}
                  />
                  Sim
                </Button>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
    </ClickAwayListener>
  );
}

const isValid = (val) => {
  if (
    parseInt(val.split(':')[0]) <= 23 &&
    parseInt(val.split(':')[1]) <= 59 &&
    parseInt(val.split(':')[2]) <= 59
  )
    return true;

  return false;
};

export default withStyles(styles)(TimeCell);
