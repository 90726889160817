import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import api from '../../../_helpers/api.config';

class ExcluiFuncionario extends Component {
  state = {
    open: true,
    success: false,
    error: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.hocHandler();
  };

  excluiFuncionario = async (e) => {
    e.preventDefault();
    const { funcionario } = this.props;
    try {
      const res = await api.delete(`/api/funcionario/${funcionario._id}`);
      if (res.status === 200 && res.data._id === funcionario._id) {
        this.setState({ success: true });
      }
    } catch (err) {
      this.setState({ error: false });
    }
  };

  render() {
    const { funcionario } = this.props;

    if (this.state.success) {
      this.props.enqueueSnackbar(
        `Funcionário ${funcionario.nome} excluído com sucesso`,
        { variant: 'success' }
      );
      return <Redirect to="/funcionarios" />;
    }

    if (this.state.error) {
      this.props.enqueueSnackbar(
        `Não foi possível excluir o funcionário ${funcionario.nome}`,
        { variant: 'error' }
      );
      return <Redirect to="/funcionarios" />;
    }

    return (
      <form onSubmit={this.excluiFuncionario}>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Excluir o funcionario {funcionario.nome}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esta ação não poderá ser desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="contained">
              Cancelar
            </Button>
            <Button
              onClick={this.excluiFuncionario}
              color="default"
              variant="contained">
              Sim, excluir
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}

export default withSnackbar(ExcluiFuncionario);
