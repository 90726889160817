import { createMuiTheme } from '@material-ui/core/styles';
import { amber, indigo, orange } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: amber,
    secondary: indigo,
    error: orange,
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fafafa' },
  },
});

export default theme;
