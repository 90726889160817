export default (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  margin: {
    margin: theme.spacing.unit,
    marginRight: 0,
    marginLeft: 0,
  },

  cssLabel: {
    '&$cssFocused': {
      // color: purple[500],
    },
  },

  buttonDel: {
    color: 'red',
  },

  pullLeft: {
    position: 'absolute',
    left: theme.spacing.unit * 2,
  },

  cssFocused: {},

  input: {
    padding: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '60px',
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      // borderColor: purple[500],
    },
  },

  paperPop: {
    background: '#ffc008',
    padding: '0.5em',

    '&:before': {
      /* tricky doubly-quoted empty string so mui parses it as truly empty */
      content: '""',
      display: 'block',
      width: '0',
      height: '0',
      position: 'absolute',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      /* border color should probably match whatever your tooltip color is */
      borderTop: '10px solid #ffc008',
      left: 'calc(50% - 10px)',
      top: '100%',
    },
  },

  button: {
    marginTop: '0.5em',
  },
  notchedOutline: {},
});
