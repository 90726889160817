import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import {
  withStyles,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import api from '../../../_helpers/api.config';
import styles from './styles';

class Cria extends Component {
  state = {
    nome: '',
    regime: '',
    error: '',
    open: true,
  };

  feedback(type, msg) {
    this.props.enqueueSnackbar(msg, { variant: type });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.hocHandler();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { nome, regime } = this.state;
    if (!nome || !regime) {
      this.setState({ error: 'Informe o nome e o regime do grupo.' });
    } else {
      try {
        const res = await api.post('/api/configs/grupo', { nome, regime });
        this.feedback('success', `Grupo ${nome} criado com sucesso`);
        this.props.updateTable(res.data);
      } catch (err) {
        console.log(err);
        this.setState({ error: 'Não foi possível criar o novo grupo.' });
      }
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <form onSubmit={this.handleSubmit}>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Criar Novo Grupo</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para criar um novo grupo, informe o nome e o regime de trabalho.
              </DialogContentText>
              <TextField
                id="nome"
                label="Nome"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                onChange={this.handleChange('nome')}
                margin="normal"
                variant="outlined"
              />

              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Regime de trabalho</FormLabel>
                <RadioGroup
                  aria-label="Regime"
                  name="regime"
                  className={classes.group}
                  value={this.state.regime}
                  onChange={this.handleChange('regime')}>
                  <FormControlLabel
                    value="Mensal"
                    control={<Radio />}
                    label="Mensal"
                  />
                  <FormControlLabel
                    value="Horista"
                    control={<Radio />}
                    label="Horista"
                  />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                color="default"
                variant="contained">
                Cancelar
              </Button>
              <Button
                onClick={this.handleSubmit}
                color="secondary"
                variant="contained">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(Cria));
