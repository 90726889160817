import React from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import styles from './styles';
import Form from './Form';
import validationSchema from './validation';

const Login = (props) => {
  const { classes, actions } = props;

  actions.logout();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Formik
          render={(props) => <Form classes={classes} {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={null}
        />
      </Paper>
    </div>
  );
};

const values = {
  email: '',
  password: '',
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedLogin = connect(
  null,
  mapDispatchToProps
)(Login);

export default withStyles(styles)(connectedLogin);
