import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Fab,
  Icon,
  Tooltip,
} from '@material-ui/core';
import api from '../../../_helpers/api.config';

const styles = (theme) => ({
  table: {
    minWidth: 700,
    marginTop: '2rem',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

class ListaFuncionarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      rows: [],
      error: null,
      notify: this.props.location.state,
    };

    this.updateTable = this.updateTable.bind(this);
  }

  async componentDidMount() {
    try {
      const res = await api.get('/api/funcionario');
      console.log(res);

      this.setState({ rows: res.data });
    } catch (err) {
      console.log(err);
      this.setState({
        error: 'Não foi possível acessar a lista de funcionários.',
      });
    }
  }

  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  updateTable(data) {
    const rows = this.state.rows;
    rows.push(data);
    this.setState({ rows });
  }

  render() {
    const { classes, match } = this.props;

    return (
      <>
        <Paper className="paper">
          <Typography variant="h5" gutterBottom>
            Funcionários
          </Typography>
          <Table className={classes.table} padding={'dense'}>
            <TableHead>
              <TableRow className="tableHeader">
                <TableCell>Funcionário</TableCell>
                <TableCell>Função</TableCell>
                <TableCell>Senha</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Grupo</TableCell>
                <TableCell>Regime</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map((funcionario) => (
                <TableRow
                  key={funcionario._id}
                  hover={true}
                  className="pointer"
                  component={Link}
                  to={{
                    pathname: `${match.url}/${funcionario._id}`,
                    state: funcionario,
                  }}>
                  <TableCell scope="row">{funcionario.nome}</TableCell>
                  <TableCell>{funcionario.funcao}</TableCell>
                  <TableCell>{funcionario.senha}</TableCell>
                  <TableCell>{funcionario.cpf}</TableCell>
                  <TableCell>{funcionario.grupo.nome}</TableCell>
                  <TableCell>{funcionario.grupo.regime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Tooltip
            title="Adicionar Funcionário"
            aria-label="Adicionar Funcionário"
            size="large">
            <Fab
              color="secondary"
              className={classes.fab}
              component={Link}
              to={{
                pathname: '/funcionarios/novo',
              }}>
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </Paper>
      </>
    );
  }
}

ListaFuncionarios.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListaFuncionarios);
