import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  withStyles,
  Paper,
  Typography,
  Icon,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import ExcluiGrupo from './exclui';
import Horista from './horista';
import Mensal from './mensal';
import api from '../../../_helpers/api.config';
import { formataDataInput, toDate } from '../../../utils';
import { styles } from './styles';

class Grupo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      funcionarios: [],
      error: null,
      excluir: false,
      grupo: this.props.location.state,
      inicio: format(startOfMonth(new Date()), 'DD/MM/YYYY'),
      fim: format(endOfMonth(new Date()), 'DD/MM/YYYY'),
    };
    this.fetchGrupoRelatorio = this.fetchGrupoRelatorio.bind(this);
    this.confirmaExclusao = this.confirmaExclusao.bind(this);
    window.scrollTo(0, 0);
  }

  async componentDidMount() {
    await this.fetchGrupoRelatorio();
  }

  async fetchGrupoRelatorio() {
    const { inicio, fim } = this.state;
    try {
      // TODO refatorar para enviar a requisiçao get no formato correto
      const res = await api.post('/api/relatorios/grupo', {
        grupo: this.state.grupo,
        periodo: {
          inicio: toDate(inicio),
          fim: toDate(fim),
        },
      });

      if (res.status === 200) {
        this.setState({
          funcionarios: res.data,
        });
      } else {
        this.feedback('error', `Erro ${res.status}`);
      }
    } catch (err) {
      console.log(err);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  handleChange = (name) => (event) => {
    const data = formataDataInput(event.target.value);
    this.setState({
      [name]: data,
    });
  };

  downloadRelatorio(tipo) {
    const { inicio, fim } = this.state;
    try {
      this.feedback('default', 'Preparando download, aguarde...');
      const res = api.post(
        '/api/relatorios/grupo/download',
        {
          grupo: this.state.grupo,
          periodo: {
            inicio: toDate(inicio),
            fim: toDate(fim),
          },
          tipo: tipo,
        },
        // {
        //   responseType: 'arraybuffer',
        // }
      );

      res.then((response) => {
        if (response.status !== 200) {
          this.feedback('error', 'Ocorreu um erro ao baixar o aquivo');
        }

        console.log("convertendo arquivo");

        // Decode base64 string
        let binaryString = window.atob(response.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        
        // Convert binary string to bytes
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        } 

        let blob = new Blob([bytes], { type: `application/${tipo}` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Fechamento ${this.state.grupo.nome}.${tipo}`;
        link.click();
      });
    } catch (err) {
      console.log(err);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  render() {
    const { classes } = this.props;
    const { grupo } = this.state; 

    return (
      <>
        <Paper className="paper">
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h5" gutterBottom>
                <Icon>group</Icon> Grupo {grupo.nome}
              </Typography>
              {this.opcoesRelatorio(classes)}
            </Grid>

            <Grid item xs={4} className={classes.regime}>
              <Typography variant="button" gutterBottom>
                Regime {grupo.regime}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {this.infoRegime(grupo.regime)}
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={4}
              justify="flex-end"
              alignContent="flex-start"
              spacing={8}
              className={classes.botoes}>
              <Grid item xs={8}>
                <Button
                  // variant="outlined"
                  fullWidth
                  size="small"
                  onClick={() => this.confirmaExclusao()}>
                  Excluir grupo
                  <Icon className={classes.rightIcon} size="small">
                    delete
                  </Icon>
                </Button>
              </Grid>

              <Grid item xs={8}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="small"
                  onClick={() => this.downloadRelatorio('pdf')}>
                  Baixar Cartões ponto
                  <Icon className={classes.rightIcon} size="small">
                    assessment
                  </Icon>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {this.horistaOuMensal()}
        </Paper>
        {this.renderModalExclusao()}
      </>
    );
  }

  confirmaExclusao() {
    this.setState({ excluir: !this.state.excluir });
  }

  renderModalExclusao() {
    if (this.state.excluir) {
      return (
        <ExcluiGrupo
          grupo={this.state.grupo}
          hocHandler={this.confirmaExclusao}
        />
      );
    }
  }

  infoRegime(regime) {
    if (regime === 'Mensal') {
      return 'No regime mensal, o sistema calcula a jornada de cada funcionário, registra horas normais, horas banco, horas totais e realiza o ponto automático.';
    }
    return 'No regime horista, o sistema calcula apenas as horas totais trabalhadas.';
  }

  horistaOuMensal() {
    if (this.state.grupo.regime === 'Mensal') {
      return (
        <Mensal
          classes={this.props.classes}
          funcionarios={this.state.funcionarios}
          match={this.props.match}
          grupo={this.state.grupo}
          periodo={{ inicio: this.state.inicio, fim: this.state.fim }}
        />
      );
    }

    if (this.state.grupo.regime === 'Horista') {
      return (
        <Horista
          classes={this.props.classes}
          funcionarios={this.state.funcionarios}
          match={this.props.match}
          grupo={this.state.grupo}
          periodo={{ inicio: this.state.inicio, fim: this.state.fim }}
        />
      );
    }
  }

  feedback(type, msg) {
    this.props.enqueueSnackbar(msg, { variant: type });
  }

  opcoesRelatorio(classes) {
    return (
      <Grid container item xs={12} spacing={8} className={classes.periodo}>
        <Grid item xs={5}>
          <TextField
            id="inicio"
            name="inicio"
            fullWidth
            label="De"
            value={this.state.inicio}
            onChange={this.handleChange('inicio')}
            variant="outlined"
            inputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="fim"
            name="fim"
            fullWidth
            label="Até"
            value={this.state.fim}
            onChange={this.handleChange('fim')}
            variant="outlined"
            inputProps={{
              className: classes.input,
            }}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            // color="secondary"
            onClick={() => this.fetchGrupoRelatorio()}>
            atualizar relatório
            <Icon className={classes.rightIcon} size="small">
              autorenew
            </Icon>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

Grupo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(Grupo));
