import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

export default props => {
  const { classes, funcionarios, match, grupo, periodo } = props;
  return (
    <Table className={ classes.table } padding={ 'dense' }>
      <TableHead>
        <TableRow className="tableHeader">
          <TableCell>Funcionário</TableCell>
          <TableCell align="center">Horas Totais</TableCell>
          <TableCell align="center">Horas Normais</TableCell>
          <TableCell align="center">Horas Banco</TableCell>
          <TableCell align="center">Valor Hora</TableCell>
          <TableCell align="center">TOTAL BANCO</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{infos(funcionarios, match, grupo, periodo)}</TableBody>
    </Table>
  );
};

const infos = (funcionarios, match, grupo, periodo) => {
  return funcionarios.map(data => {
    const { funcionario, result } = data;
    const totais = result[result.length - 1];
    return (
      <TableRow
        key={funcionario._id}
        hover={true}
        className="pointer"
        component={Link}
        to={{
          pathname: `${match.url}/relatorio`,
          state: {
            funcionario,
            pontos: result,
            grupo,
            periodo,
          },
        }}>
        <TableCell component="th" scope="eow">
          {funcionario.nome}
        </TableCell>
        <TableCell align="center">
          {((totais.total - totais.faltante) / 3600).toFixed(2)}
        </TableCell>
        <TableCell align="center">
          {(totais.normal / 3600).toFixed(2)}
        </TableCell>
        <TableCell align="center">{(totais.extra / 3600).toFixed(2)}</TableCell>
        <TableCell align="center">
          R$ {parseFloat(funcionario.valorHora).toFixed(2)}{' '}
        </TableCell>
        <TableCell align="center">
          R${' '}
          {(
            ((totais.extra) / 3600) *
            parseFloat(funcionario.valorHora)
          ).toFixed(2)}{' '}
        </TableCell>
      </TableRow>
    );
  });
};
