import { combineReducers } from 'redux';

import login from '../components/Login/redux/reducer';
import feedback from '../components/_Common/Feedback/redux/reducer';

const rootReducer = combineReducers({
  login,
  feedback,
});

export default rootReducer;
