export const styles = theme => ({
  table: {
    minWidth: 700,
    marginTop: '2rem',
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  input: {
    padding: '9px 14px',
  },

  periodo: {
    marginTop: '20px',
  },

  regime: {
    marginTop: '50px',
  },

  botoes: {
    // marginTop: '15px',
  },
});
