import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || 'https://3rlmkud6tf6bgr5t2ckedq4ka40hynzb.lambda-url.sa-east-1.on.aws/',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export default api;
