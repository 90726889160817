import React, { Component } from 'react';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import Cria from './cria';
import { withStyles, Paper, Typography } from '@material-ui/core';
import api from '../../../_helpers/api.config';
import styles from './styles';
import validationSchema from '../validation';
import { errorHandler } from '../../../utils';

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grupos: [],
      isLoading: true,
      success: false,
      fail: false,
      error: '',
    };
  }

  async componentDidMount() {
    try {
      const res = await api.get('/api/configs/grupo');

      if (res.data.length === 0)
        this.props.enqueueSnackbar('Não há nenhum grupo cadastrado!', {
          variant: 'error',
        });

      this.setState({
        grupos: res.data,
        isLoading: false,
        // success: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ error: 'Não foi possível acessar a lista de grupos.' });
    }
  }

  feedback(type, msg) {
    this.props.enqueueSnackbar(msg, { variant: type });
  }

  render() {
    const values = {
      nome: '',
      cpf: '',
      senha: '',
      funcao: '',
      valorHora: '',
      grupo: '',
      regime: false,
      grupos: this.state.grupos,
      manhaEntrada: '08:00',
      manhaSaida: '12:00',
      tardeEntrada: '13:00',
      tardeSaida: '17:00',
      sabadoEntrada: '08:00',
      sabadoSaida: '12:00',
      classes: this.props.classes,
      fabLabel: 'Salvar',
    };

    if (this.state.isLoading) {
      return <p>Carregando...</p>;
    }

    if (this.state.success) {
      return <Redirect to="/funcionarios" />;
    }

    return (
      <Paper className="paper">
        <Typography variant="h5" gutterBottom>
          Novo funcionário
        </Typography>
        <Formik
          render={(props) => <Cria {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            // console.log(values);

            const funcionario = {
              nome: values.nome,
              senha: values.senha,
              grupo: values.grupo._id,
              funcao: values.funcao,
              cpf: values.cpf,
              valorHora: values.valorHora,
              regime: values.regime,
              jornada: {
                manha: {
                  entrada: values.manhaEntrada,
                  saida: values.manhaSaida,
                },
                tarde: {
                  entrada: values.tardeEntrada,
                  saida: values.tardeSaida,
                },
                sabado: {
                  entrada: values.sabadoEntrada,
                  saida: values.sabadoSaida,
                },
              },
            };

            try {
              this.feedback('default', `Criando novo funcionário...`);
              await api.post('/api/funcionario', { funcionario });
              this.feedback(
                'success',
                `Funcionário ${funcionario.nome} criado com sucesso`
              );
              actions.resetForm();
              this.setState({ success: true });
            } catch (error) {
              this.feedback('error', errorHandler(error.response.data));
            }

            // actions.setSubmitting(false);
          }}
        />
      </Paper>
    );
  }
}

export default withSnackbar(withStyles(styles)(InputForm));
