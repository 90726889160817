import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import api from '../../../_helpers/api.config';

class ExcluiGrupo extends Component {
  state = {
    open: true,
    success: false,
    error: false,
    grupos: [],
    destino: '',
  };

  async componentDidMount() {
    try {
      const grupos = await api.get('/api/configs/grupo');

      if (grupos.data.length === 1) {
        this.props.enqueueSnackbar('Não existem outros grupos!', {
          variant: 'error',
        });
      } else {
        this.setState({
          grupos: grupos.data.filter((grupo) =>
            grupo._id !== this.props.grupo._id ? grupo : false
          ),
        });
      }
    } catch (err) {
      console.log(err);
      this.props.enqueueSnackbar(
        'Não foi possível consultar a lista de grupos.',
        { variant: 'error' }
      );
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.hocHandler();
  };

  excluiGrupo = async (e) => {
    e.preventDefault();
    const { grupo } = this.props;
    const { destino } = this.state;

    if (destino === '') {
      this.props.enqueueSnackbar(
        'Impossível excluir grupo sem mover os funcionários',
        {
          variant: 'error',
        }
      );
      return false;
    }

    try {
      const res = await api.delete(`/api/configs/grupo/${grupo._id}`, {
        data: destino,
      });
      if (res.status === 200) {
        this.setState({ success: true });
      }
    } catch (err) {
      this.setState({ error: false });
    }
  };

  render() {
    const { grupo } = this.props;
    const { grupos } = this.state;

    if (this.state.success) {
      this.props.enqueueSnackbar(`Grupo ${grupo.nome} excluído com sucesso`, {
        variant: 'success',
      });
      return <Redirect to="/grupos" />;
    }

    if (this.state.error) {
      this.props.enqueueSnackbar(
        `Não foi possível excluir o grupo ${grupo.nome}`,
        { variant: 'error' }
      );
      return <Redirect to="/grupos" />;
    }

    return (
      <form onSubmit={this.excluiGrupo}>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Excluir o grupo {grupo.nome}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esta ação não poderá ser desfeita.
            </DialogContentText>
            <DialogContentText>
              Selecione para qual grupo os funcionários devem ser realocados.
            </DialogContentText>
            <br />
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="destino">Mover para o grupo</InputLabel>
              <Select
                value={this.state.destino}
                onChange={this.handleChange('destino')}
                input={
                  <OutlinedInput labelWidth={140} name="destino" id="destino" />
                }>
                {grupos.map((_grupo) => (
                  <MenuItem value={_grupo} key={_grupo._id}>
                    {_grupo.nome} - ({_grupo.regime})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="contained">
              Cancelar
            </Button>
            <Button
              onClick={this.excluiGrupo}
              color="default"
              variant="contained">
              Sim, excluir
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}

export default withSnackbar(ExcluiGrupo);
