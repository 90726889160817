export const success = (msg) => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    message: msg,
    options: {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
  },
});

export const fail = (msg) => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    message: msg,
    options: {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
  },
});

export const warning = (msg) => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    message: msg,
    options: {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
  },
});

export const removeSnackbar = (key) => ({
  type: 'REMOVE_SNACKBAR',
  key,
});
