export default (theme) => ({
  root: {
    // flexGrow: ,
    // display: 'flex',
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },

  title: {
    marginRight: '4em',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  linkColor: {
    color: 'white !important',
  },

  links: {
    display: 'flex',
    flexDirection: 'row',
    //   position: 'relative',
    //   // left: 0,
    //   borderRadius: theme.shape.borderRadius,
    //   marginRight: theme.spacing.unit * 2,
    //   marginLeft: 0,
    //   width: '100%',
    //   [theme.breakpoints.up('sm')]: {
    //     marginLeft: theme.spacing.unit * 3,
    //     width: 'auto',
    //   },
  },
});
