import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from './types';

import * as service from './service';
import * as feedback from '../../_Common/Feedback/redux/actions';

const request = () => ({ type: LOGIN_REQUEST });
const success = () => ({ type: LOGIN_SUCCESS });
const failure = () => ({ type: LOGIN_FAILURE });

export const login = (credentials) => {
  const { email, password } = credentials;
  return async (dispatch) => {
    dispatch(request());
    const res = await service.login(email, password);
    if (res.success) {
      dispatch(success());
    } else {
      dispatch(feedback.warning(res.error));
      dispatch(failure(res.error));
    }
  };
};

export const logout = () => {
  service.logout();
  if (window.location.pathname !== '/') window.location.reload();
  return { type: LOGOUT };
};
