export function isLastChar(index, str) {
  return index === str.length - 1;
}

export function onlyNumbers(str) {
  return str.replace(/[^\d]/g, '');
}

export function formataDataInput(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 8)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([1, 3].includes(index)) return `${result}/`;
      }
      return result;
    }, '');
}

export function toDate(dateStr) {
  const [day, month, year] = dateStr.split('/');
  return new Date(year, month - 1, day);
}

export function formataHora(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 5)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([1].includes(index)) return `${result}:`;
      }
      return result;
    }, '');
}

export function formataValor(value) {
  const numericData = onlyNumbers(value);
  return numericData
    .slice(0, 4)
    .split('')
    .reduce((acc, current, index) => {
      const result = `${acc}${current}`;
      if (!isLastChar(index, numericData)) {
        if ([1].includes(index)) return `${result}.`;
      }
      return result;
    }, '');
}

export function errorHandler(errors) {
  if (typeof errors === 'object') {
    let result = '';
    for(let error of errors) {
      result += `${error.message}.`;
    }
    return result;
  }
  
  return errors;
}
