export default theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },

  toolbar: theme.mixins.toolbar,

  formControl: {
    margin: `${theme.spacing.unit * 3} 0`,

  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});