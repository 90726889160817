import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Icon,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles';

function ButtonAppBar(props) {
  const { classes } = props;
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
          <Icon>menu</Icon>
        </IconButton> */}
        <Typography variant="h6" color="inherit" className={classes.title}>
          Banco de Horas
        </Typography>

        <div className={classes.links}>
          <Link to="/grupos" component={RouterLink} underline="none">
            <ListItem button>
              <ListItemIcon className={classes.linkColor}>
                <Icon>group</Icon>
              </ListItemIcon>
              <Typography
                variant="button"
                color="inherit"
                className={classes.linkColor}>
                Grupos
              </Typography>
            </ListItem>
          </Link>

          <Link to="/funcionarios" component={RouterLink} underline="none">
            <ListItem button>
              <ListItemIcon className={classes.linkColor}>
                <Icon>account_box</Icon>
              </ListItemIcon>
              <Typography
                variant="button"
                color="inherit"
                className={classes.linkColor}>
                Funcionários
              </Typography>
            </ListItem>
          </Link>
        </div>
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);
