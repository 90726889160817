import * as Yup from 'yup';

const validationSchema = Yup.object({
  nome: Yup.string('').required('Este campo é obrigatório'),
  cpf: Yup.string('')
    .required('Este campo é obrigatório')
    .min(14, 'CPF inválido'),
  senha: Yup.string('')
    .min(4, 'A senha deve ter 4 números e não deve começar com zero')
    .max(4, 'A senha deve ter 4 números e não deve começar com zero')
    .required('Este campo é obrigatório'),
  funcao: Yup.string('').required('Este campo é obrigatório'),
  valorHora: Yup.number('Valor inválido')
    .min(1, 'Valor Inválido')
    .max(999, 'Valor Inválido')
    .required('Este campo é obrigatório'),
  grupo: Yup.string('').required('Este campo é obrigatório'),
  regime: Yup.boolean(),
  manhaEntrada: Yup.string().when('regime', {
    is: true,
    then: Yup.string('')
      .min(5, 'Formato inválido')
      .max(5, 'Formato inválido')
      .required('Este campo é obrigatório'),
    otherwise: Yup.string().min(0),
  }),
  manhaSaida: Yup.string().when('regime', {
    is: true,
    then: Yup.string('')
      .min(5, 'Formato inválido')
      .max(5, 'Formato inválido')
      .required('Este campo é obrigatório'),
    otherwise: Yup.string().min(0),
  }),
  tardeEntrada: Yup.string().when('regime', {
    is: true,
    then: Yup.string('')
      .min(5, 'Formato inválido')
      .max(5, 'Formato inválido')
      .required('Este campo é obrigatório'),
    otherwise: Yup.string().min(0),
  }),
  tardeSaida: Yup.string().when('regime', {
    is: true,
    then: Yup.string('')
      .min(5, 'Formato inválido')
      .max(5, 'Formato inválido')
      .required('Este campo é obrigatório'),
    otherwise: Yup.string().min(0),
  }),
  sabadoEntrada: Yup.string().when('regime', {
    is: true,
    then: Yup.string('')
      .min(5, 'Formato inválido')
      .max(5, 'Formato inválido')
      .required('Este campo é obrigatório'),
    otherwise: Yup.string().min(0),
  }),
  sabadoSaida: Yup.string().when('regime', {
    is: true,
    then: Yup.string('')
      .min(5, 'Formato inválido')
      .max(5, 'Formato inválido')
      .required('Este campo é obrigatório'),
    otherwise: Yup.string().min(0),
  }),
});

export default validationSchema;
